<template>
  <div style="">
    <portal to="title-page">
      <h1>Listado de reservaciones por dia</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      :loading="loading"
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="search"
                label="Buscar"
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                v-model="panel"
                :items="panels"
                item-text="text"
                item-value="value"
                label="panel"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-btn class="mt-md-3" small @click="searchReservations()"
                elevation="2"
                color="primary"
              ><v-icon>mdi-magnify</v-icon> Buscar</v-btn>
            </v-col>
            <!-- <v-col cols="6" sm="6" md="6">
              <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
            </v-col> -->
          </v-row>
        </div>
        <v-divider></v-divider>
        <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
      </template>
      <template v-slot:item.confirmed="{ item }">
        <v-icon 
          v-if="!item.confirmationInfo"
          class=""
          @click.stop="openModalConfirmationInfo(item)"
        >
          {{mdiCheckboxBlankCircleOutline}}
        </v-icon>
        <v-icon 
          v-if="item.confirmationInfo"
          class="green--text"
        >
          {{ mdiCheckCircle }}
        </v-icon>
        <br>
        <template v-if="item.pickupDateTime">
          {{$moment(item.pickupDateTime.seconds * 1000).format('HH:mma')}}
        </template>
      </template>
      <template v-slot:item.code="{ item }">
        {{item.code}}
      </template>
      <template v-slot:item.pax="{ item }">
        <div v-if="item.adults > 0">Adultos: {{item.adults}}</div>
        <div v-if="item.elderlies > 0">A.Mayores: {{item.elderlies}}</div>
        <div v-if="item.children > 0">Niños: {{item.children}}</div>
        <div v-if="item.infants > 0">Infantes: {{item.infants}}</div>
      </template>
      <template v-slot:item.customer="{ item }">
        <div >{{item.customerEmail}}</div>
        <div >{{item.customerName}} {{item.customerLastname}}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <a href="#" style="text-decoration: none" @click="confirmDelete(item, $event)">
          <v-icon 
            class="mr-2"
            color="red"
          >
            <!-- @click="$router.push({path:`/backend/taquilla/reservations/${item.id}/edit`})" -->
            mdi-trash-can
          </v-icon>
        </a>
      </template>
      <template v-slot:item.status="{ item }">
        <BtnStatus :icon="mdiPencil" :status="item.status" v-on:click.native="test"/>
      </template>
      <template  v-slot:item.paymentStatus="{ item }">
        <BtnPaymentStatus :icon="mdiPencil" :status="item.paymentStatus" />
      </template>
      <template v-slot:item.total="{ item }">
        ${{item.total}}<small>{{item.currency}}</small>
      </template>
    </v-data-table>
    <ConfirmationInfo v-if="reservation"  :reservation="reservation"/>
    <v-dialog
      v-if="reservation"
      v-model="dialogConfirmDeleteReservation"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline red white--text">
          Eliminar reservación
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <Loading :active="loadingDeleteReservation" :fullPage="true" />
          Esta seguro que desea eliminar la reservación {{ reservation.code }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogConfirmDeleteReservation = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="deleteReservation"
          >
            <v-icon>mdi-trash-can</v-icon>
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Reservation from "@/models/Reservation";
  import ConfirmationInfo from "@/components/reservations/ConfirmationInfo";
  import BtnStatus from "@/components/reservations/BtnStatus";
  import BtnPaymentStatus from "@/components/reservations/BtnPaymentStatus";
  import { /*mdiClose, mdiArrowLeft*/mdiPencil, mdiCheckboxBlankCircleOutline, mdiCheckCircle } from '@mdi/js';
  // import {mapState} from 'vuex'
  // import firebase from 'firebase/app'
  // import 'firebase/auth'

  // const axios = require('axios');

  export default {
    name:'TaquillaReservations',
    components:{ConfirmationInfo, BtnStatus, BtnPaymentStatus},
    data() {
      let date = this.$moment()//.add(1, 'day');
      return {
        mdiPencil:mdiPencil,
        mdiCheckboxBlankCircleOutline:mdiCheckboxBlankCircleOutline,
        mdiCheckCircle:mdiCheckCircle,
        //mdiClose:mdiClose,
        //mdiArrowLeft:mdiArrowLeft,
        //date:'',
        date:date.format('YYYY-MM-DD'),
        reservationModel:new Reservation,
        items:null,
        unsubscribe:null,
        search:'',
        selected:[],
        panel:'',
        panels:[{text:'Todos', value: ''}, {text: 'Web', value: 'website'}, {text: 'Taquilla', value: 'taquilla'}],
        loading:true,
        dialogConfirmDeleteReservation:false,
        loadingDeleteReservation:false,
        tableHeaders:[
          {
            text: 'Pickup',
            align: 'center',
            sortable: true,
            value: 'confirmed',
          },
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'code',
            width:"140px"
          },
          {
            text: 'Tour',
            align: 'start',
            sortable: true,
            value: 'tour',
          },
          // {
          //   text: 'Fecha',
          //   align: 'start',
          //   sortable: true,
          //   value: 'pax',
          // },
          {
            text: 'Pax',
            align: 'start',
            sortable: true,
            value: 'pax',
          },
          {
            text: 'Cliente',
            align: 'start',
            sortable: true,
            value: 'customer',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          },
          {
            text: 'Pago',
            align: 'start',
            sortable: true,
            value: 'paymentStatus',
          },
          {
            text: 'Total',
            align: 'center',
            sortable: true,
            value: 'total',
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        menu2: false,
        dialog: false,
        reservation: null,
        snackbar:{
          show:false,
          color:'',
          message:''
        },
      };
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Admin')
    },
    mounted() {
      this.searchReservations();
    },
    methods:{
      searchReservations() {
        if (this.unsubscribe) {
          this.unsubscribe();
        }
        this.loading = true;
        this.unsubscribe = this.reservationModel.findByDate(this.$moment(this.date)).onSnapshot(snap => {
          this.items = [];
          snap.forEach(doc => {
            if (this.panel == 'website') {
              if (doc.data().panel == 'website') {
                this.items.push(doc.data());
              }
            } else if (this.panel == 'taquilla') {
              if (doc.data().panel == 'taquilla') {
                this.items.push(doc.data());
              }
            } else if (this.panel == '') {
              this.items.push(doc.data());
            }
          })
          this.loading = false;
        })
      },
      openModalConfirmationInfo(reservation) {
        this.reservation = reservation
        this.$store.dispatch('reservation/setDialogConfirmationInfo', true)
      },
      confirmDelete(r, e) {
        e.preventDefault()
        this.reservation = r
        this.dialogConfirmDeleteReservation = true
      },
      deleteReservation() {
        this.loadingDeleteReservation = true
        this.reservationModel.delete(this.reservation.id)
          .then(async (response) => {
            if (response.status == 'OK') {
              this.dialogConfirmDeleteReservation = false 
              this.loadingDeleteReservation = false
              this.reservation = null
              this.showSnackbarSuccess('La reservación se ha eliminado correctamente')
            } else {
              //this.dialogConfirmDeleteReservation = true 
              this.loadingDeleteReservation = false
              this.showSnackbarError('Error al eliminar la reservación. Si el problema persiste contacte al Administrador')
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingDeleteReservation = false
            this.showSnackbarError(error.message)
          });
      },
      redirectTo(url, event) {
        if (event) {
          event.preventDefault();
        }
        this.$router.push({path:url})
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    watch: {
      panel: function() {
        this.searchReservations()
      }
    }
    //computed: mapState(['dialogConfirmationInfo'])
  }
</script>